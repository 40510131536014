import React,  { useState, useEffect }  from 'react'
import { host } from '../../../Utils';
import axios from 'axios';
import './LogrosExperiencias.css'
import logrosPg1 from './../../../images/orientacionCiudadana/logrosExperiencias/Linea del tiempo convocatorias.png'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Documentos from '../../../components/Documentos';
export const LogrosExperiencias = () => {

  const [selectedConv, setSelectedConv] = useState(null)
  const [datosActual, setDatosActual]=useState({})
  const [primerConvocatoria, setPrimerConvocatoria]=useState({})
  const [segundaConvocatoria, setSegundaConvocatoria]=useState({})
  const [terceraConvocatoria, setTerceraConvocatoria]=useState({})
  const [cuartaConvocatoria, setCuartaConvocatoria]=useState({})

  const handleButton=(conv)=>{
    setSelectedConv(conv)
  }
  useEffect(()=>{
    axios.get(host() + "/Publico/DatosParticipacionCiudadana").then(res => {
     res.data.forEach(element => {
      if(element.Actual===true){
        setDatosActual(element)
        return
      }
      switch (element.NumeroConvocatoria){
        case 1:
          setPrimerConvocatoria(element)
          return
        case 2:
          setSegundaConvocatoria(element)
          return
        case 3:
          setTerceraConvocatoria(element)
          return
        case 4:
          setCuartaConvocatoria(element)
          return
      }
     });
  })
  },[])


  const renderSelectedConvComponent=()=>{
    switch (selectedConv) {
      case 'primera':
        return <PrimeraConv conv={primerConvocatoria} />;
      case 'segunda':
        return <SegundaConv conv={segundaConvocatoria} />;
      case 'tercera':
        return <TerceraConv conv={terceraConvocatoria} />;
      case 'cuarta':
        return <CuartaConv conv={cuartaConvocatoria} />;
      default:
        return null;
    }
  }
  return (
    <>
    <div className='fondo1'>
    <div className="text-container" >
        <h1  style={{fontWeight:'bolder'}}>LOGROS Y EXPERIENCIAS</h1>
    </div>
    </div>
    <div style={{marginTop:'-300px'}}>
      <div style={{textAlign:'center'}}>
        <h2 style={{fontWeight:'bolder', color:'#097275', textAlign:'justify'}}>Trayectoria de las convocatorias de participación ciudadana </h2>
        <p style={{textAlign:'justify'}}>La 5ta. convocatoria se encuentra vigente, esperando que seas parte de la integración del Programa Anual de Auditorías 2024-2025.  </p>
        <img alt='' className='col-12' src={logrosPg1}/>
        <p style={{textAlign:'justify'}}>En los últimos años el Instituto Superior de Auditoría y Fiscalización ha publicado <strong style={{color:'#097275'}}>cuatro convocatorias</strong> de participación ciudadana, con un total de <strong style={{color:'#c96c1c'}}>39 solicitudes recibidas</strong> y <strong style={{color:'#832347'}}>28 propuestas seleccionadas</strong> para su integración al Programa Anual de Auditorías del período en cuestión.  </p>
      </div>
      <div className='component'>
        <div className='box'>
          <div className='convocatoria'>
            <h1>4</h1>
            <h5>Convocatorias</h5>
          </div>
        </div>
        <div className='box'>
        <div className='solicitudes'>
            <h1>{datosActual.SolicitudesRecibidas}</h1>
            <h5>Solicitudes  recibidas</h5>
          </div>
        </div>
        <div>
        <div className='propuestas'>
            <h1>{datosActual.PropuestasSeleccionadas}</h1>
            <h5>Propuestas seleccionadas</h5>
          </div>
        </div>
      </div>
    <div className='aniosConvocatoria'>
      <button className={selectedConv==='primera'? 'convocatoriaSelected' : ''} onClick={()=>handleButton('primera')}><h1>2020</h1></button>
      <button className={selectedConv==='segunda'? 'convocatoriaSelected' : ''} onClick={()=>handleButton('segunda')}><h1>2021</h1></button>
      <button className={selectedConv==='tercera'? 'convocatoriaSelected' : ''} onClick={()=>handleButton('tercera')}><h1>2022</h1></button>
      <button className={selectedConv==='cuarta'? 'convocatoriaSelected' : ''} onClick={()=>handleButton('cuarta')}><h1>2023</h1></button>
    </div>
    {/* //////////////////////////// GENERICO //////////////////////////// */}
      
      {/* ////////////////////////// GENERICO /////////////////////////////// */}
      {renderSelectedConvComponent()}
    </div>
    </>
  )
}

export const PrimeraConv=(conv)=>{
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return(
    <>
      <div style={{marginTop:'20px'}}>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{marginRight:'10px'}}>
            <div className='rojo'></div>
            <div className='naranja'></div>
            <div className='amarillo'></div>
            <div className='azul'></div>
          </div>
          <div className='d-flex'>
          <h1>1ra. Convocatoria de Participación Ciudadana</h1>
          </div>
        </div>
        <div className='component'>
        <div className='box'>
          <div className='solicitudes'>
            <h1>{conv.conv.SolicitudesRecibidas}</h1>
            <h5>Solicitudes</h5>
          </div>
        </div>
          <div className='separador'>
        <div className='ciudadanaOrganizacion'>
            <h1>{conv.conv.SolicitudesCiudadanas}</h1>
            <h5>Ciudadanas</h5>
          </div>
        <div className='ciudadanaOrganizacionAbajo'>
            <h1>{conv.conv.SolicitudesOrganizacion}</h1>
            <h5>Organizaciones o instituciones</h5>
          </div>
          </div>
        <div className='boxfinal'>
        <div className='propuestas'>
            <h1>{conv.conv.PropuestasSeleccionadas}</h1>
            <h5>Propuestas seleccionadas</h5>
          </div>
        </div>
      </div>
      </div>
      <div className='botones'>
        <button> <a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6362">
              {" "}
              Convocatoria
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6363">
              {" "}
              Políticas
            </a> </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6365">
              {" "}
              Propuestas
            </a>  </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6361">
              {" "}
              Acta del Comité
            </a>  </button>
              <button> <a style={{ color: 'white' }} href="https://intranet.isaf.gob.mx/Publico/Documento/6364">PAA 20-21</a></button>
        <button  onClick={toggle}>Informes Individuales  </button>
        <Modal isOpen={modal} toggle={toggle} fullscreen={'xl'} scrollable={true}>
        <ModalHeader toggle={toggle}>Informes Individuales </ModalHeader>
        <ModalBody>
         <Documentos number="FA-0286" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      </div>
      <div className='d-flex me-5'>
        <div className='card p-3'>
          <p>Cabe señalar que, la diferencia entre las solicitudes seleccionadas y los Informes Individuales de las primeras tres convocatorias, se debe a que el participante realizó una solicitud referente a un tema en particular, incorporándose como parte del proceso de fiscalización.  </p>
        </div>
      </div>
    </>
  )
}
export const SegundaConv=(conv)=>{
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return(
    <>
      <div style={{marginTop:'20px'}}>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{marginRight:'10px'}}>
            <div className='rojo'></div>
            <div className='naranja'></div>
            <div className='amarillo'></div>
            <div className='azul'></div>
          </div>
          <div>
          <h1>2da. Convocatoria de Participación Ciudadana</h1>
          </div>
        </div>
        <div className='component'>
        <div className='box'>
          <div className='solicitudes'>
            <h1>{conv.conv.SolicitudesRecibidas}</h1>
            <h5>Solicitudes</h5>
          </div>
        </div>
        <div className='separador'>
        <div className='ciudadanaOrganizacion'>
            <h1>{conv.conv.SolicitudesCiudadanas}</h1>
            <h5>Ciudadanas</h5>
          </div>
        <div className='ciudadanaOrganizacionAbajo'>
            <h1>{conv.conv.SolicitudesOrganizacion}</h1>
            <h5>Organizaciones o instituciones</h5>
          </div>
          </div>
        <div className='boxfinal'>
        <div className='propuestas'>
            <h1>{conv.conv.PropuestasSeleccionadas}</h1>
            <h5>Propuestas seleccionadas</h5>
          </div>
        </div>
      </div>
      </div>
      <div className='botones'>
      <button> <a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6367">
              {" "}
              Convocatoria
            </a></button>
        <button>  <a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6369">
              {" "}
              Políticas
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6370">
              {" "}
              Propuestas
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6366">
              {" "}
              Acta del Comité 
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6368">
              {" "}
              PAA 21-22
            </a> </button>
        <button  onClick={toggle}>Informes Individuales  </button>
        <Modal isOpen={modal} toggle={toggle} fullscreen={'xl'} scrollable={true}>
        <ModalHeader toggle={toggle}>Informes Individuales </ModalHeader>
        <ModalBody>
         <Documentos number="FA-0287" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      </div>
      <div className='d-flex me-5' >
        <div className='card p-3'>
        <p>Cabe señalar que, la diferencia entre las solicitudes seleccionadas y los Informes Individuales de las primeras tres convocatorias, se debe a que el participante realizó una solicitud referente a un tema en particular, incorporándose como parte del proceso de fiscalización.  </p>
        </div>
      </div>
    </>
  )
}
export const TerceraConv=(conv)=>{

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return(
    <>
      <div style={{marginTop:'20px'}}>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{marginRight:'10px'}}>
            <div className='rojo'></div>
            <div className='naranja'></div>
            <div className='amarillo'></div>
            <div className='azul'></div>
          </div>
          <div>
          <h1>3ra. Convocatoria de Participación Ciudadana</h1>
          </div>
        </div>
        <div className='component'>
        <div className='box'>
          <div className='solicitudes'>
            <h1>{conv.conv.SolicitudesRecibidas}</h1>
            <h5>Solicitudes</h5>
          </div>
        </div>
        <div className='separador'>
        <div className='ciudadanaOrganizacion'>
            <h1>{conv.conv.SolicitudesCiudadanas}</h1>
            <h5>Ciudadanas</h5>
          </div>
        <div className='ciudadanaOrganizacionAbajo'>
            <h1>{conv.conv.SolicitudesOrganizacion}</h1>
            <h5>Organizaciones o instituciones</h5>
          </div>
          </div>
        <div className='boxfinal'>
        <div className='propuestas'>
            <h1>{conv.conv.PropuestasSeleccionadas}</h1>
            <h5>Propuestas seleccionadas</h5>
          </div>
        </div>
      </div>
      </div>
      <div className='botones'>
        <button style={{width:'12vh',height:'12vh'}}><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6372">
              {" "}
              Convocatoria
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6374">
              {" "}
              Políticas
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6375">
              {" "}
              Propuestas
            </a>  </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6371">
              {" "}
              Acta del Comité  
            </a></button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6373">
              {" "}
              PAA 22-23 
            </a> </button>
        <button  color="danger" onClick={toggle}>Informes Individuales  </button>
        <Modal isOpen={modal} toggle={toggle} fullscreen={'xl'} scrollable={true}>
        <ModalHeader toggle={toggle}>Informes Individuales </ModalHeader>
        <ModalBody>
         <Documentos number="FA-0288" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      </div>
      <div className='d-flex me-5' >
        <div className='card p-3'>
        <p>Cabe señalar que, la diferencia entre las solicitudes seleccionadas y los Informes Individuales de las primeras tres convocatorias, se debe a que el participante realizó una solicitud referente a un tema en particular, incorporándose como parte del proceso de fiscalización.  </p>
        </div>
      </div>
    </>
  )
}
export const CuartaConv=(conv)=>{
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return(
    <>
      <div style={{marginTop:'20px'}}>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{marginRight:'10px'}}>
            <div className='rojo'></div>
            <div className='naranja'></div>
            <div className='amarillo'></div>
            <div className='azul'></div>
          </div>
          <div>
          <h1>4ta. Convocatoria de Participación Ciudadana</h1>
          </div>
        </div>
        <div className='component'>
        <div className='box'>
          <div className='solicitudes'>
            <h1>{conv.conv.SolicitudesRecibidas}</h1>
            <h5>Solicitudes</h5>
          </div>
        </div>
        <div className='separador'>
        <div className='ciudadanaOrganizacion'>
            <h1>{conv.conv.SolicitudesCiudadanas}</h1>
            <h5>Ciudadanas</h5>
          </div>
        <div className='ciudadanaOrganizacionAbajo'>
            <h1>{conv.conv.SolicitudesOrganizacion}</h1>
            <h5>Organizaciones o instituciones</h5>
          </div>
          </div>
        <div className='boxfinal'>
        <div className='propuestas'>
            <h1>{conv.conv.PropuestasSeleccionadas}</h1>
            <h5>Propuestas seleccionadas</h5>
          </div>
        </div>
      </div>
      </div>
      <div className='botones'>
        <button style={{width:'12vh',height:'12vh'}}><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6377">
              {" "}
              Convocatoria 
            </a> </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6379">
              {" "}
              Políticas 
            </a> </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6380">
              {" "}
              Propuestas
            </a>  </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6376">
              {" "}
              Acta del Comité  
            </a>  </button>
        <button><a style={{color:'white'}} href="https://intranet.isaf.gob.mx/Publico/Documento/6378">
              {" "}
              PAA 23-24 
            </a></button>
        <button color="danger" onClick={toggle}>Informes Individuales  </button>
        <Modal isOpen={modal} toggle={toggle} fullscreen={'xl'} scrollable={true}>
        <ModalHeader toggle={toggle}>Informes Individuales </ModalHeader>
        <ModalBody>
         <Documentos number="FA-0289" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
      </div>
      <div className='d-flex me-5' >
        <div className='card p-3' >
          <p>De acuerdo con lo establecido en el artículo 67, fracción B de la Constitución Política del Estado Libre y Soberano de Sonora (CPES), el Instituto Superior de Auditoría y Fiscalización revisará anualmente las cuentas públicas del año inmediato anterior.   

En este sentido, le informamos que el ejercicio fiscal 2023 se encuentra actualmente en proceso de fiscalización, de conformidad con el artículo 46 de la Ley de Fiscalización Superior para el Estado de Sonora (LFSES), los resultados se entregarán a más tardar el día 30 de agosto del presente al H.  Congreso del Estado.   </p>
        </div>
      </div>
    </>
  )
}

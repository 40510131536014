import React, { useState, useEffect } from 'react'
import { Nav,NavItem,NavLink,TabContent,TabPane,Row,Col,Card,CardTitle,CardText,Button } from 'reactstrap'
import './PruebaDisenio.css'
import { ConvocatoriaAbierta } from './components/ConvocatoriaAbierta'
import { LogrosExperiencias } from './components/LogrosExperiencias'
import {ManualDeOrientacionCiudadana} from './components/ManualDeOrientacionCiudadana'
import { smallScreen } from '../../Utils'
import logoCiudadano from './../../images/orientacionCiudadana/logrosExperiencias/LOGOComiteParticipacionCiudadana.jpg'
import { Calendario } from './components/Calendario'
import { DatosGenerales } from './components/DatosGenerales'
import { QueEsLaParticipacionCiudadana } from './components/QueEsLaParticipacionCiudadana'
import { Politicas } from './components/Politicas'

export const ParticipacionCiudadana = () => {
    const [activeTab, setActiveTab] = useState('1'); // Estado para controlar la pestaña activa
    const [isChecked, setIsChecked] = useState(false);
    const [atEndOfPage, setAtEndOfPage] = useState(false);
    const toggleTab = (tab) => {
      if (activeTab !== tab) {
        window.scrollTo(0, 0);
        setActiveTab(tab);
        setIsChecked(false);
      }
    };
    
  useEffect(() => {
    window.scrollTo(0, 0)
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.clientHeight;
      const scrollTop = window.scrollY;

      // Verifica si el usuario ha llegado al final de la página
      if (windowHeight + scrollTop >= documentHeight) {
        setAtEndOfPage(true);
      } else {
        setAtEndOfPage(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const menuStyle = {
    background: 'rgba(255, 255, 255, 0.945)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(10.8px)',
    WebkitBackdropFilter: 'blur(10.8px)',
    border: '1px solid rgba(255, 255, 255, 0.7)',
    position: 'fixed',
    maxWidth: '300px',
    maxHeight: '480px',
    zIndex: '-0',
    transition: 'top 1s ease', // Transición suave de 0.5 segundos para la propiedad 'top'
    ...(atEndOfPage && { top: 'auto', bottom: '400px' }), // Agrega bottom solo si estamos al final de la página
  };

  return (
    <>
    <div className='d-flex altura '>
      <>
      <input type='checkbox' id='check' checked={isChecked} onChange={() => setIsChecked(!isChecked)}  />
      <label for='check' className='checkbtn'>
      <i class="bi bi-list"></i>
      </label>
      </>
        <div className='centro' >
        <Nav vertical style={menuStyle} className='menu p-2'>
              <h1  className='card-titulo'>PARTICIPACIÓN CIUDADANA EN LA FISCALIZACIÓN</h1>
          <NavItem>
            <NavLink  className={activeTab === '1' ? 'active selected' : ''} onClick={() => toggleTab('1')}>
            ¿QUÉ ES LA PARTICIPACIÓN CIUDADANA?
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink  className={activeTab === '2' ? 'active selected' : ''} onClick={() => toggleTab('2')}>
             CONVOCATORIA ABIERTA
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '3' ? 'active selected' : ''} onClick={() => toggleTab('3')}>
            POLÍTICAS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '4' ? 'active selected' : ''} onClick={() => toggleTab('4')}>
             CALENDARIO
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '5' ? 'active selected' : ''} onClick={() => toggleTab('5')}>
             MANUAL DE ORIENTACIÓN CIUDADANA
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '6' ? 'active selected' : ''} onClick={() => toggleTab('6')}>
              LOGROS Y EXPERIENCIAS
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab === '7' ? 'active selected' : ''} onClick={() => toggleTab('7')}>
             DATOS INFORMATIVOS
            </NavLink>
          </NavItem>
        </Nav>
        </div>
    <TabContent activeTab={activeTab} className='navegacion' >
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
                <div className='context'>
                <QueEsLaParticipacionCiudadana/>
                </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="12">
            <div className='context'>
            <ConvocatoriaAbierta/>
                </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col sm="12">
            <div className='context'>
              <Politicas/>
                </div>
            
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col sm="12">
            <div className='context'>
              <Calendario/>
                </div>
            
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col sm="12">
            <div className='manual'>
            <ManualDeOrientacionCiudadana/>
                </div>
            
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="6">
          <Row>
            <Col sm="12">
            <div className='context'>
            <LogrosExperiencias/>
                </div>
            
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="7">
          <Row>
            <Col sm="12">
            <div className='context'>
            <DatosGenerales/>
                </div>
            
            </Col>
          </Row>
        </TabPane>
      </TabContent>
            </div>
        </>

  )
}

import React, { useEffect, useState } from 'react';
import {host, hostSigas, useConstructor} from '../../Utils';
import '../InformesIndividuales.css';
import encontramosIcon from '../../images/informe-ciudadano/encontramos.png'
import observacionesIcon from '../../images/informe-ciudadano/observaciones.png'
import recomendacionesIcon from '../../images/informe-ciudadano/recomendaciones.png'
import tipoAuditoriaIcon from '../../images/informe-ciudadano/tipoAuditoria.png'

import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Select from 'react-select';

export function InformeCiudadano(props) {

    const [, setEntes] = useState([]);
    const [tiposAuditoria, setTiposAuditoria] = useState([9]);
    const [timeoutId, setTimeoutId] = useState(0);
    const [enteSelected, setEnteSelected] = useState({});
     const [Informe, setInforme] = useState("");
    const [Success, setSuccess] = useState(0);

    const [numPages, setNumpages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [innerSize, setInnerSize] = useState(0);

    useConstructor(() => {
        props.setTitle("Informes Ciudadanos")
    });
    
    useEffect(() => {
        updateInforme(enteSelected);
        let widthSize = window.innerWidth;
        console.log(widthSize);
        setInnerSize(widthSize);
    }, [enteSelected, setInnerSize]);
    
    

  

  function onDocumentLoadSuccess({ numPages }) {
    setNumpages(numPages);
    }

      

 
    const updateInforme =(ente)=>{

        if (ente.id !== undefined){
            setSuccess(0);
                     
            setInforme("")
           axios.get(hostSigas()+ '/api/Informes/Ciudadano/'+ente.id+'/18/')
           .then(res=>{
            setSuccess(1)
            console.log('infrome:  ', res.data)  
             
             axios.get(hostSigas() +'/api/File/DownloadPublic/' + res.data)
             .then((x) => {
                console.log(x.data.blockBlob.uri + x.data.sharedPolicy)
                 setInforme(x.data.blockBlob.uri + x.data.sharedPolicy)
                 
             }).catch(x=> {
                console.log(x)
                setSuccess(2)
            })


             document.getElementById("main").scrollIntoView();
           }).catch(x=> {
               setInforme("")
               setSuccess(2)
               console.log(x)})

        }
    }

   
    

    const handleSelected = (newValue)=>{
        setEnteSelected(newValue);
        


    }

      
      

    const loadOptions = (inputValue, callback) => {
        if (!inputValue) {
            setEntes([])
                callback([]);
            return;
        }
        clearTimeout(timeoutId);
        var timeout_id = setTimeout(()=>{         
            axios.get(hostSigas() + "/api/Entes/website/" + inputValue).then(res => {
            if (res.data.length > 0) {
                var result = res.data.map(x => {
                    x.value = x.id;
                    x.label = (x.nombre !== x.nombreCorto? x.nombreCortoCompleto : x.nombre);
                    return x;
                });
                setEntes(result)
                callback(result);
            } else {
                setEntes([])
                callback([]);
            }            
        }).catch(res => console.log(res));
        },1000);
        setTimeoutId(timeout_id)
    };



    return (
            
           <div style={{minHeight:350}}>
            <div className='text-center'>
             Seleccione el sujeto fiscalizado para ver el Informe Ciudadano correspondiente de las auditorías ejecutadas a la Cuenta Pública 2022.  
             </div>
            <div className='flex-center'>
                <div className='ic-filters'>
                    <AsyncSelect
                        className={'ic-select'}
                        cacheOptions
                        loadOptions={loadOptions}
                        defaultOptions
                        onChange={handleSelected}
                        placeholder="Seleccione Ente"
                        loadingMessage={({ inputValue }) => !inputValue ? "Escriba para comenzar a buscar" : "Cargando..."}
                        noOptionsMessage={({ inputValue }) => !inputValue ? "Escriba para comenzar a buscar" : "Sin coincidencias"}
                    />
                   

                </div>
            </div>
            <div id="main" className='' style={{display: Success===1 ? 'block': 'none'}}>
            
            {innerSize >= 500 ? (
              <>
                <Document 
                loading={"Cargando Informe..."}
                  renderMode="canvas"
                  className="p-3 border bg-light flex-center"
                                file={Informe}
                  onLoadSuccess={onDocumentLoadSuccess}
                    
                >
                 <Page className="widthInherit"
                    pageIndex={0} 
                    fixed 
                    pageNumber={pageNumber}
                    width = {1000}                    
                ></Page>                                
                </Document>                
              </>
            ) : (
              <>
               <Document 
                  renderMode="canvas"
                  className="p-3 border bg-light flex-center"
                                file={Informe}
                  onLoadSuccess={onDocumentLoadSuccess}
                    
                >
                 <Page className="widthInherit"
                    pageIndex={0} 
                    fixed 
                    pageNumber={pageNumber}
                    width = {innerSize}                    
                ></Page>                                
                </Document>         
              </>
            )}
            

            </div>
            
            
            <div className='center' 
            style={{display: (
                enteSelected.id !== undefined && 
                Success === 2) ? 'block': 'none', fontWeight:600}}>
                    El ente público no tiene observaciones relevantes de las cuales hacer mención.              
            </div>



            </div>
    );
}



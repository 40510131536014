import React, { useState, useEffect } from 'react';
import { useConstructor } from '../../Utils';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Documentos from "../../components/Documentos";

export function Sevac  (props) { 
    useConstructor(() => props.setTitle("SEVAC"));
    const [activeTab, setActiveTab] = useState('1');
    const [isChecked, setIsChecked] = useState(false);
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            setIsChecked(false);
        }
    };
    useEffect(() => {
        
    }, [])

    return (
        <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={activeTab === '1' ? 'active selected' : ''} onClick={() => toggleTab('1')}
                    >
                        Primer Trimestres 2024
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={activeTab === '2' ? 'active selected' : ''} onClick={() => toggleTab('2')}
                    >
                        Segundo Trimestres 2024
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <div className="flex-container">
                      <Documentos number="FA-0283"></Documentos>
                     </div>
                </TabPane>
                <TabPane tabId="2">
                    <div className="flex-container">
                        <Documentos number="FA-0294"></Documentos>
                    </div>
                </TabPane>
            </TabContent>
            
      </div>
    );
  
}

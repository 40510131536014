import React from 'react'
import './DatosGenerales.css'
import datosPg1 from './../../../images/orientacionCiudadana/logrosExperiencias/Diap 4 Sinaloa.png'

export const DatosGenerales = () => {


  return (
    <>
      <div className='fondo3'>
        <div className="text-container" >
          <h1  style={{fontWeight:'bolder'}}>DATOS INFORMATIVOS</h1>
        </div>
      </div>
      <div style={{marginTop:'-300px'}}>
      <div>
            <h1 className='d-flex ' style={{fontSize:'80px',color:'black', margin:'0'}}><strong style={{color:'#7c5c15', marginTop:'0px',fontSize:'100px'}} >5</strong><p className=' morope-heavy' style={{fontSize:'40px',marginLeft:'10px',marginTop:'45px',color:'#7c5c15'}}>Datos informativos de la participación ciudadana</p></h1>
          </div>
      <div className='p-3' style={{ overflowX: 'auto' }}>

        <hr style={{ color: 'gray' }} />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <div style={{ marginRight: '10px' }}>
            <div className='rojo'></div>
            <div className='naranja'></div>
            <div className='amarillo'></div>
            <div className='azul'></div>
          </div>
          <div>
            <h1 className='d-flex morope-heavy' style={{fontSize:'60px', margin:'0', color:'#832347'}}>1  <h1 className='mt-3 ms-2 morope-heavy'> Solicitudes ciudadanas por género</h1></h1>
          </div>
        </div>
        <div className='banderas'>
          <ul>
            <li>
              <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                <h2 className='convocatoriaTitulo1'>1ra. Convocatoria</h2>
                <div className='bandera' style={{ width: '100%' }}>
                  <div className='datos'>

                    <div className='bloque'>

                      <h6>Hombre</h6>
                      <div className='numero'>
                        0
                      </div>
                      <div className='porcentaje'>
                        0%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Mujer</h6>
                      <div className='numero'>
                        1
                      </div>
                      <div className='porcentaje'>
                        50%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Anonimo</h6>
                      <div className='numero'>
                        1
                      </div>
                      <div className='porcentaje'>
                        50%
                      </div>
                    </div>
                  </div>
                  <div className='numero1'>
                    <p>Total</p>
                    <h1>2</h1>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                <h2 className='convocatoriaTitulo2'>2da. Convocatoria</h2>
                <div className='bandera' style={{ width: '100%' }}>
                  <div className='datos'>

                    <div className='bloque'>

                      <h6>Hombre</h6>
                      <div className='numero'>
                        0
                      </div>
                      <div className='porcentaje'>
                        0%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Mujer</h6>
                      <div className='numero'>
                        1
                      </div>
                      <div className='porcentaje'>
                        11%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Anonimo</h6>
                      <div className='numero'>
                        8
                      </div>
                      <div className='porcentaje'>
                        89%
                      </div>
                    </div>
                  </div>
                  <div className='numero2'>
                    <p>Total</p>
                    <h1>9</h1>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className='d-flex' style={{ flexWrap: 'wrap' }}>
                <h2 className='convocatoriaTitulo3'>3ra. Convocatoria</h2>
                <div className='bandera' style={{ width: '100%' }}>
                  <div className='datos'>

                    <div className='bloque'>

                      <h6>Hombre</h6>
                      <div className='numero'>
                        7
                      </div>
                      <div className='porcentaje'>
                        70%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Mujer</h6>
                      <div className='numero'>
                        3
                      </div>
                      <div className='porcentaje'>
                        30%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Anonimo</h6>
                      <div className='numero'>
                        0
                      </div>
                      <div className='porcentaje'>
                        0%
                      </div>
                    </div>
                  </div>
                  <div className='numero3'>
                    <p>Total</p>
                    <h1>10</h1>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className='d-flex' style={{ flexWrap: 'wrap' }}>

                <h2 className='convocatoriaTitulo4'>4ta. Convocatoria</h2>
                <div className='bandera' style={{ width: '100%' }}>
                  <div className='datos'>

                    <div className='bloque'>

                      <h6>Hombre</h6>
                      <div className='numero'>
                        3
                      </div>
                      <div className='porcentaje'>
                        100%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Mujer</h6>
                      <div className='numero'>
                        0
                      </div>
                      <div className='porcentaje'>
                        0%
                      </div>
                    </div>
                    <div className='sep'></div>
                    <div className='bloque'>

                      <h6>Anonimo</h6>
                      <div className='numero'>
                        0
                      </div>
                      <div className='porcentaje'>
                        0%
                      </div>
                    </div>
                  </div>
                  <div className='numero4'>
                    <p>Total</p>
                    <h1>3</h1>
                  </div>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div>
      <hr style={{ color: 'gray' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
        <div style={{ marginRight: '10px' }}>
          <div className='rojo'></div>
          <div className='naranja'></div>
          <div className='amarillo'></div>
          <div className='azul'></div>
        </div>
        <div>
        <h1 className='d-flex morope-heavy' style={{fontSize:'60px', margin:'0', color:'#ccb656'}}>2 <h1 className='mt-3 ms-2 morope-heavy'> Origen de las solicitudes recibidas</h1></h1>
    
        </div>
      </div>
      <div className='tarjetas'>
        <div className='d-flex'>
          <div className='internacional card'>
            <h4>INTERNACIONAL</h4>
          </div>
          <div className='card inetrnacionalint'>
            <ul>
              <li>
                <p>
                  Oficina de las Naciones Unidas contra la droga y el delito (UNODC)
                </p>
              </li>
            </ul>
          </div>

        </div>
        <div className='d-flex'>
          <div className='nacional card'>
            <h4>NACIONAL</h4>
          </div>
          <div className='card nacionalint'>
            <ul>
              <li>
                <p>
                  Instituto Mexicano de Contadores Públicos (IMCP)
                </p>
              </li>
            </ul>
          </div>

        </div>
        <div className='d-flex'>
          <div className='estatal card'>
            <h4>ESTATAL</h4>
          </div>
          <div className='card estatalint'>
            <ul>
              <li>
                <p>
                  Confederación Patronal de la República Mexicana (COPARMEX)
                </p>
              </li>
              <li>
                <p>
                  Cámara Nacional de la Industria de la Transformación (CANACINTRA)
                </p>
              </li>
              <li>
                <p>
                  Cámara Mexicana de la Industria de la Construcción (CMIC)
                </p>
              </li>
              <li>
                <p>
                  Instituto Sonorense de Administración Pública (ISAP)
                </p>
              </li>
            </ul>
          </div>

        </div>
        <div className='d-flex'>
          <div className='municipal card'>
            <h4>MUNICIPAL</h4>
          </div>
          <div className='card municipalint'>
            <ul>
              <li>
                <p>
                  Hermosillo
                </p>
              </li>
              <li>
                <p>
                  Magdalena de Kino
                </p>
              </li>
              <li>
                <p>
                  Navojoa
                </p>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <hr style={{ color: 'gray' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
        <div style={{ marginRight: '10px' }}>
          <div className='rojo'></div>
          <div className='naranja'></div>
          <div className='amarillo'></div>
          <div className='azul'></div>
        </div>
        <div>
        <h1 className='d-flex morope-heavy' style={{fontSize:'60px', margin:'0', color:'#c96c1c'}}>3 <h1 className='mt-3 ms-2 morope-heavy'> Participaciones por sector laboral</h1></h1>
    
    
        </div>
      </div>

      <div className='checadores'>
        <div className='carta'>
          <div className='checadorCant1'>
            
            <h2 className='checadorCantHeader'>
              <p>Total</p>6</h2>
          </div>
          <div className='checador card'>
            <div style={{ marginTop: '130px' }}></div>
            <div className='checador-block'>
              <h6>
                Público
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>1</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>17%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Privado
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>3</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>50%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Informal
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>2</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>33%</p>
              </div>

            </div>
          </div>
          <h2 className='convocatoriaTitulo1' style={{ marginLeft: '0' }}>1ra. <br /> Convocatoria</h2>
        </div>
        <div className='carta'>
          <div className='checadorCant2'>
            <h2 className='checadorCantHeader'> <p>Total</p> 12</h2>
          </div>
          <div className='checador card'>
            <div style={{ marginTop: '130px' }}></div>
            <div className='checador-block'>
              <h6>
                Público
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>3</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>25%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Privado
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>0</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>0%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Informal
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>9</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>75%</p>
              </div>

            </div>
          </div>
          <h2 className='convocatoriaTitulo2' style={{ marginLeft: '0' }}>2da. <br /> Convocatoria</h2>
        </div>
        <div className='carta'>
          <div className='checadorCant3'>
            <h2 className='checadorCantHeader'> <p>Total</p> 18</h2>
          </div>
          <div className='checador card'>
            <div style={{ marginTop: '130px' }}></div>
            <div className='checador-block'>
              <h6>
                Público
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>9</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>50%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Privado
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>1</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>5%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Informal
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>8</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>45%</p>
              </div>

            </div>
          </div>
          <h2 className='convocatoriaTitulo3' style={{ marginLeft: '0' }}>3ra. <br /> Convocatoria</h2>
        </div>
        <div className='carta'>
          <div className='checadorCant4'>
            <h2 className='checadorCantHeader'> <p>Total</p> 3</h2>
          </div>
          <div className='checador card'>
            <div style={{ marginTop: '130px' }}></div>
            <div className='checador-block'>
              <h6>
                Público
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>0</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>0%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Privado
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>0</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>0%</p>
              </div>

            </div>
            <div className='checador-block'>
              <h6>
                Informal
              </h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>3</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>100%</p>
              </div>

            </div>
          </div>
          <h2 className='convocatoriaTitulo4' style={{ marginLeft: '0' }}>4ta. <br /> Convocatoria</h2>
        </div>
      </div>
      <hr style={{ color: 'gray' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
        <div style={{ marginRight: '10px' }}>
          <div className='rojo'></div>
          <div className='naranja'></div>
          <div className='amarillo'></div>
          <div className='azul'></div>
        </div>
        <div>
        <h1 className='d-flex morope-heavy' style={{fontSize:'60px', margin:'0', color:'#ccb656'}}>4 <h1 className='mt-3 ms-2 morope-heavy'> Propuestas recibidas por ámbito a fiscalizar</h1> </h1>
            <h4> </h4>
    
        </div>
      </div>
      <div className='drops'>
        <div className='drop'>
          <div className='circle1'>
            <div className='circleInt'>
              <h2 className="color1"> <p>Total</p>6</h2>
            </div>
          </div>
          <div className='triangle1'></div>
          <div className='separadorCircle1'></div>
          <div className='sep' style={{ height: '80px' }}></div>
          <div className='dropcard'>
            <div className='dropcard-block'>
              <h6>Estatal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>4</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>67%</p>
              </div>
            </div>
            <div className='sepHorizontal'></div>
            <div className='dropcard-block'>
              <h6>Municipal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>2</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>33%</p>
              </div>
            </div>
          </div>
          <h2 className='convocatoriaTitulo1' style={{ marginLeft: '0', marginTop:'20px' }}>1ra.<br /> Convocatoria</h2>
        </div>
        <div className='drop'>
          <div className='circle2'>
            <div className='circleInt'>
              <h2 className="color2"><p>Total</p>12</h2>
            </div>
          </div>
          <div className='triangle2'></div>
          <div className='separadorCircle2'></div>
          <div className='sep' style={{ height: '80px' }}></div>
          <div className='dropcard'>
            <div className='dropcard-block'>
              <h6>Estatal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>8</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>67%</p>
              </div>
            </div>
            <div className='sepHorizontal'></div>
            <div className='dropcard-block'>
              <h6>Municipal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>4</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>33%</p>
              </div>
            </div>
          </div>
          <h2 className='convocatoriaTitulo2' style={{ marginLeft: '0', marginTop:'20px' }}>2da. <br /> Convocatoria</h2>
        </div>
        <div className='drop'>
          <div className='circle3'>
            <div className='circleInt'>
              <h2 className="color3"><p>Total</p>18</h2>
            </div>
          </div>
          <div className='triangle3'></div>
          <div className='separadorCircle3'></div>
          <div className='sep' style={{ height: '80px' }}></div>
          <div className='dropcard'>
            <div className='dropcard-block'>
              <h6>Estatal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>5</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>28%</p>
              </div>
            </div>
            <div className='sepHorizontal'></div>
            <div className='dropcard-block'>
              <h6>Municipal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>13</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>72%</p>
              </div>
            </div>
          </div>
          <h2 className='convocatoriaTitulo3' style={{ marginLeft: '0', marginTop:'20px' }}>3ra. <br /> Convocatoria</h2>
        </div>
        <div className='drop'>
          <div className='circle4'>
            <div className='circleInt'>
              <h2 className="color4"><p>Total</p>3</h2>
            </div>
          </div>
          <div className='triangle4'></div>
          <div className='separadorCircle4'></div>
          <div className='sep' style={{ height: '80px' }}></div>
          <div className='dropcard'>
            <div className='dropcard-block'>
              <h6>Estatal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>1</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>33%</p>
              </div>
            </div>
            <div className='sepHorizontal'></div>
            <div className='dropcard-block'>
              <h6>Municipal</h6>
              <div className='d-flex'>
                <p style={{ padding: '20px' }}>2</p>
                <div className='sep'></div>
                <p style={{ padding: '20px' }}>67%</p>
              </div>
            </div>
          </div>
          <h2 className='convocatoriaTitulo4' style={{ marginLeft: '0', marginTop:'20px' }}>4ta. <br /> Convocatoria</h2>
        </div>
      </div>
      </div>
      <hr style={{ color: 'gray' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
        <div style={{ marginRight: '10px' }}>
          <div className='rojo'></div>
          <div className='naranja'></div>
          <div className='amarillo'></div>
          <div className='azul'></div>
        </div>
        <div>
        <h1 className='d-flex morope-heavy' style={{fontSize:'60px', margin:'0', color:'#097275'}}>5 <h1 className='mt-3 ms-2 morope-heavy'>Principales enfoques de las propuestas por PAA </h1> </h1>
    
        </div>
      </div>
      <img alt='' className='col-12' src={datosPg1}/>
    </>
  )
}
import React, { useEffect, useState } from "react";
import { useConstructor } from '../../Utils';
import { Bar } from "react-chartjs-2";
import { hostSigas, smallScreen } from "../../Utils";
import axios from "axios";
import portada from "../../images/portada2022-2023.png";
import { host } from "../../Utils";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

export default function ProgramaAuditoriasGraficas() {
    const [_DatosDuros, set_DatosDuros] = useState([]);

    useEffect(() => {
        //console.log(hostSigas() + "/api/AvancePlaneacion/4");
        axios.get(hostSigas() + "/api/AvancePlaneacion/4").then((res) => {
            set_DatosDuros(res.data);
            console.log(res.data);
        });
    }, []);

    const data = {
        labels: _DatosDuros.map((x) => x.mes + " de " + x.ano),
        datasets: [
            {
                type: "bar",
                label: "Auditorías planeadas",
                backgroundColor: "rgba(204,192,86,0.8)",
                borderColor: "rgba(223,197,90,1)",
                data: _DatosDuros.map((x) => x.planeadas),
                order: 1,
                borderWidth: 2,
            },
            {
                type: "bar",
                label: "Auditorías ejecutadas",
                borderColor: "rgba(131,35,71,0.8)",
                backgroundColor: "rgba(131,35,71,1)",
                data: _DatosDuros.map((x) => x.ejecutadas),
                order: 1,
                borderWidth: 2,
            },
            {
                type: "line",
                label: "Informes individuales Estado",
                borderColor: "rgba(9, 114, 117, 1)",
                borderWidth: 2,
                order: 3,
                data: _DatosDuros.map((x) => x.informesEstado),
            },
            {
                type: "line",
                label: "Informes individuales Municipios",
                display: false,
                borderColor: "rgba(201, 108, 28, 1)",
                borderWidth: 2,
                order: 3,
                data: _DatosDuros.map((x) => x.informesMunicipio),
            },
            {
                type: "line",
                label: "Total de Informes Individuales",
                borderColor: "rgba(168, 168, 167, 1)",
                borderWidth: 3,
                order: 4,

                data: _DatosDuros.map((x) => x.informes),
            },
        ],
    };

    var options = {
        plugins: {
            legend: {
                reverse: false,
            },
            tooltip: {
                //reverse: true,
            },
            responsive: true,
        },
        maintainAspectRatio: false,
    };

    return (
        <div style={{ marginBottom: "23%" }}>
            <div className="flex-container" style={{ justifyContent: "space-evenly", alignItems: "center" }}>
                <div className="graph-container" style={{ width: '90%' }}>
                    <Bar data={data} height={"100"} options={options} />
                    <p className="mt-10">
                        <span className="text-bold">Nota: </span> Adicionalmente, se realiza una Auditoría en Tiempo Real (ATR) solicitada de Obra Pública al municipio de Hermosillo,
                        misma que inició el mes de febrero de 2022.
                    </p>

                    <p style={{ marginTop: "-7px" }}>
                        Con base a lo establecido en el artículo 18, fracción V de LFSES, el Programa Anual de Auditorías,
                        puede modificarse, según sea requerido, en función de las circunstancias que se
                        presenten durante la ejecución de dichos programas.
                    </p>
                    <div>
                        <div className="d-flex flex-row align-content-center align-items-center mb-2">
                            <div style={{ height: "50px", width: "50px", backgroundColor: "#d6cd78", marginRight: "5px" }}></div>
                            <p>
                                <span className="text-bold">Auditorías planeadas: </span>
                                Hace referencia a la totalidad de auditorías que integran el PAA 23-24, con respecto a la fecha establecida en éste.
                            </p>
                        </div>
                        <div className="d-flex flex-row align-content-center align-items-center mb-2">
                            <div style={{ height: "50px", width: "50px", backgroundColor: "#832347", marginRight: "5px" }}></div>
                            <p>
                                <span className="text-bold">Auditorías ejecutadas: </span>
                                Contempla las auditorías que fueron iniciadas, ya sea que se encuentren en proceso o terminadas,
                                con base a la fecha que se haya registrado en SIGAS.
                            </p>
                        </div>
                        <div className="d-flex flex-row align-content-center align-items-center mb-2">
                            <div style={{ height: "48px", width: "48px", backgroundColor: "#e5e5e5", marginRight: "5px", borderStyle: "solid", borderColor: "#6baaac" }}></div>
                            <p>
                                <span className="text-bold">Informes Individuales Estado: </span>
                                Incluye las auditorías al Gobierno del Estado concluidas, cuyo informe ha sido cargado en el SIGAS y se encuentra testado en PDF.
                            </p>
                        </div>
                        <div className="d-flex flex-row align-content-center align-items-center mb-2">
                            <div style={{ height: "48px", width: "48px", backgroundColor: "#e5e5e5", marginRight: "5px", borderStyle: "solid", borderColor: "#c96d1e" }}></div>
                            <p className="">
                                <span className="text-bold">Informes Individuales Municipios: </span>
                                Incluye las auditorías a los gobiernos municipales concluidas, cuyo informe ha sido cargado en el SIGAS y se encuentra testado en PDF.
                            </p>
                        </div>
                        <div className="d-flex flex-row align-content-center">
                            <div style={{ height: "48px", width: "53px", backgroundColor: "#e5e5e5", marginRight: "5px", borderStyle: "solid", borderColor: "#a8a8a7" }}></div>
                            <p>
                                <span className="text-bold">Total de Informes Individuales: </span>
                                Incluye las auditorías concluidas del Gobierno del Estado y los gobiernos municipales, cuyo informe ha sido cargado en el SIGAS y se encuentra testado en PDF.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
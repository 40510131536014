import React from "react";
import { smallScreen } from "../../Utils";
import PresupuestoIngresos from "../../images/programaanual/presupuesto-ingresos-2023.png";
import PresupuestoEgresos from "../../images/programaanual/presupuesto-egresos-2023.png";
import GraficaInteractiva from "../../images/programaanual/grafica-interactiva.png";
import ProgramaAnualAuditorias from "../../images/programaanual/programa-anual-auditorias.png";


export function ProgramaAnual() {  
    return (
        <div className="container-fluid cuenta-publica-main">
            <div className="flex-container d-flex flex-column align-content-center" style={{ alignItems: 'center' }}>
                <div style={{ marginBottom: "30px", textAlign: "center", padding: '5px 25px 5px 20px', fontSize: smallScreen() ? '24px' : '42px', fontWeight: 600, color: '#832347' }}>PROGRAMA ANUAL DE AUDITORÍAS</div>
                <div style={{ width: "30%", marginBottom: "30px", textAlign: "center", backgroundColor: '#d6b74d', borderRadius: '25px', padding: '5px 25px 5px 20px', fontSize: smallScreen() ? '24px' : '42px', fontWeight: 600, color: '#fff' }}>2023 - 2024</div>
                <span style={{fontSize: 22}}>Con base al artículo 18, fracción V y 27 de la Ley de Fiscalización Superior para el Estado de Sonora,
                    el ISAF publica el Programa Anual de Auditorías 2023 - 2024, correspondiente al ejercicio 2023, en el cual se señalaban la totalidad de los sujetos de fiscalización
                    y los períodos de revisión, entre otros aspectos relevantes.</span>
                <br />
            </div>
            <div className="flex-container cuenta-buttons" style={{ justifyContent: "space-evenly" }}>
                <a href="/auditorias/programaauditoriadocumento"><img src={ProgramaAnualAuditorias} style={smallScreen() ? { width: "150px" } : {}} /></a>
                <a href="/auditorias/programaauditoriagraficas"><img src={GraficaInteractiva} style={smallScreen() ? { width: "150px" } : {}} /></a>
                <a href="/auditorias/presupuestosingresos"><img src={PresupuestoIngresos} style={smallScreen() ? { width: "150px" } : {}} /></a>
                <a href="/auditorias/presupuestosegresos"><img src={PresupuestoEgresos} style={smallScreen() ? { width: "150px" } : {}} /></a>
            </div>
        </div>
    );
  
}
